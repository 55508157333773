
import { defineComponent, ref, computed, watch, nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
// import SwiperCore, { Thumbs } from 'swiper'
// import { Thumbs } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import type SwiperType from 'swiper/types/swiper-class'
import 'swiper/swiper.scss'

import { ImagePreview } from 'vant'
import 'vant/lib/image-preview/style'
import '@vant/touch-emulator'
export default defineComponent({
  components: { Swiper, SwiperSlide },
  setup() {
    // 获取数据
    const currentIndex = ref(0)
    const mySwiper = ref()
    const imgArr = ref([])
    const store = useStore()
    const route = useRoute()

    const id = route.params.id
    store.dispatch('article/getDetailAction', id)
    const detail = computed(() => {
      return store.state.article.detail
    })
    watch(detail, (newval) => {
      console.log(mySwiper.value)
      imgArr.value = newval.tmb4
      nextTick(() => {
        mySwiper.value.slideToLoop(0, 0)
      })
    })

    // setTimeout(() => {
    //   mySwiper.value.update()
    // }, 2000)
    const changeIndex = (index: number) => {
      mySwiper.value.slideToLoop(index, 0)
    }
    const onSwiper = (swiper: SwiperType) => {
      mySwiper.value = swiper
    }
    const onSlideChange = (swiper: SwiperType) => {
      console.log(swiper.realIndex)

      currentIndex.value = swiper.realIndex
    }
    const toPrev = () => {
      mySwiper.value.slidePrev()
    }
    const toNext = () => {
      mySwiper.value.slideNext()
    }

    const picArr = computed(() => {
      return imgArr.value.map((item) => {
        return 'https://www.coherehouse.com' + item
      })
    })
    const showPreview = (item: string, index: number) => {
      ImagePreview({
        images: picArr.value,
        startPosition: index,
        closeable: true,
        showIndicators: true
      })
    }
    const router = useRouter()
    const tohome = () => {
      router.push({
        name: 'Home',
        query: {
          isback: 1
        }
      })
    }
    const toList = () => {
      router.push({
        name: 'worklist'
      })
    }
    return {
      imgArr,
      detail,
      currentIndex,
      changeIndex,
      onSlideChange,
      onSwiper,
      toPrev,
      toNext,
      showPreview,
      tohome,
      toList
    }
  }
})
